import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import styles from './AllPlacesPage.module.scss'
import { useTranslation } from 'react-i18next';
import { getRequest, postCsrf } from '../../services/fetch/AxiosHelper';
import { Helmet } from 'react-helmet';
import TitlesData from '../../data/TitlesData';
import SortingOrders from '../../data/SortingOrders';
import ClipLoader from 'react-spinners/ClipLoader';
import PlaceCard from '../../components/mui/placeCard/PlaceCard';
import { Select, MenuItem, Pagination, Collapse} from '@mui/material';
import { ReactComponent as CustomArrowIcon } from '../../assets/images/common/orange-dropdown.svg'
import CheckIcon from '@mui/icons-material/Check';
import DynamicBreadcrumbs from '../../components/mui/breadcrumbs/DynamicBreadcrumbs';
import { Button, Checkbox, Popover } from '@mui/material';
import { ReactComponent as  WhiteDropDownLess } from '../../assets/images/common/white-dropdown-less.svg'
import { ReactComponent as  WhiteDropDownMore } from '../../assets/images/common/white-dropdown-more.svg'
import OrangeButtonVioletHover from '../../components/mui/orangeButton/OrangeButtonVioletHover';
import clearImageOrange  from '../../assets/images/common/clear-orange.svg';
import clearImageWhite from '../../assets/images/common/clear-white.svg';
import largeWhiteCloseIcon from '../../assets/images/common/large-white-close-icon.svg';
import { useNavigate, useLocation  } from 'react-router-dom';
import noPlaces from '../../assets/images/common/no-places.svg';
import { CommonUtils } from '../../utils/CommonUtils';
import { useParams } from 'react-router';

const initialPage = 1;
const perPage = 15;

const AllPlacesPage = () => {
  const location = useLocation();
  const { SortingOrder, SortingOrderTranslation } = SortingOrders();
  const navigate = useNavigate();
  const params = useParams()
  const initialMount = useRef(true);
  const { t, i18n } = useTranslation();
  const {Titles} = TitlesData()
  const [sortingOrder, setSortingOrder] = useState(SortingOrder.ALPHABETICAL)
  const [placesList, setPlacesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState(null)
  const [selectedCategories, setSelectedCategories] = useState([])
  const prevLanguage = useRef(i18n.language);
  const prevSearch = useRef(location.search)
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'simple-popover' : undefined;
  const { getFormmatedWorkingHours } = CommonUtils();
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(0);

  const [filters, setFilters] = useState({ categories: [], features: [], classifications: [] });
  const [selectedFilters, setSelectedFilters] = useState({ categoryIds: [], classificationsIds: [], featureIds: [] });
  const [removedSelectedFilters, setRemovedSelectedFilters] = useState(false);

  const [openClassifications, setOpenClassifications] = useState(false)
  const [openCategories, setOpenCategories] = useState(false)
  const [openFeatures, setOpenFeatures] = useState(false)

  const getClassificationNameById = (id) => {
    const classification = filters.classifications?.find(classification => classification.id === id);
    return classification ? classification.name : null;
  }

  const getCategoryNameById = (id) => {
    const category = filters.categories?.find(category => category.id === id);
    return category ? category.name : null;
  }

  const parseFiltersFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      categoryIds: searchParams.get('categoryIds') ? searchParams.get('categoryIds').split(',').map(Number) : [],
      classificationsIds: searchParams.get('classificationsIds') ? searchParams.get('classificationsIds').split(',').map(Number) : [],
      featureIds: searchParams.get('featureIds') ? searchParams.get('featureIds').split(',').map(Number) : [],
      sortingOrder: searchParams.get('sortingOrder') || SortingOrder.ALPHABETICAL,
      page: searchParams.get('page') || initialPage,
      scrollY: searchParams.get('scrollY') || 0
    };
  }

  const getFeatureNameById = (id) => {
    const feature = filters.features?.find(feature => feature.id === id);
    return feature ? feature.name : null;
  }

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  
  const handleDropdownChange = (event) => {
    const newSortingOrder = event.target.value;
    setPlacesList([]);
    setSortingOrder(newSortingOrder);
    
    const searchParams = new URLSearchParams(selectedFilters);
    searchParams.set('page', currentPage);
    searchParams.set('sortingOrder', newSortingOrder);
    const newParam = searchParams.toString()
    navigate(`?${newParam}`, { replace: true });
    const request = {
      categoryIds: selectedFilters.categoryIds,
      classificationsIds: selectedFilters.classificationsIds,
      featureIds: selectedFilters.featureIds,
      sortingOrder: newSortingOrder,
      page: currentPage
    }
    getPlaces(currentPage, request)
  };

  const getFiltersByCriteria = (criteria) => {
    setRemovedSelectedFilters(false)
    postCsrf(`filter/available?language=${i18n.language}`, JSON.stringify(criteria))
            .then((response) => response.data)
            .then((data) => {
              
              setFilters(prevFilters => ({
                ...prevFilters,
                categories: data.categories || [],
                classifications: data.classifications || [],
                features: data.features || []
              }));

              const updatedSelectedFilters = { ...selectedFilters };
              if (data.categories != null) {
                updatedSelectedFilters.categoryIds = selectedFilters.categoryIds.filter(id => data.categories?.find(sbct => sbct.id === id));
              }
              if (data.classifications != null) {
                  updatedSelectedFilters.classificationsIds = selectedFilters.classificationsIds.filter(id => data.classifications?.find(cls => cls.id === id));
              }
              if (data.features != null) {
                  updatedSelectedFilters.featureIds = selectedFilters.featureIds.filter(id => data.features?.find(feat => feat.id === id));
              }

              if ((updatedSelectedFilters.classificationsIds?.length < selectedFilters.classificationsIds?.length) 
              || (updatedSelectedFilters.featureIds?.length < selectedFilters.featureIds?.length)
              || (updatedSelectedFilters.categoryIds?.length < selectedFilters.categoryIds?.length)) {
                setRemovedSelectedFilters(true)
                setSelectedFilters(updatedSelectedFilters)
              }
            })
            .catch((error) => {
                console.log(error)
            });
  }

  const clearAllFilters = () => {
    setSelectedFilters(prevFilters => ({
      ...prevFilters,
      categoryIds: [],
      classificationsIds: [],
      featureIds: []
    }));
  }

  const handleFilterChange = (filterType, filterId) => {
    setSelectedFilters((prevSelectedFilters) => {
      const isSelected = prevSelectedFilters[filterType].includes(filterId);
  
      let newFilterIds;
      if (isSelected) {
        newFilterIds = prevSelectedFilters[filterType].filter(id => id !== filterId);
      } else {
        newFilterIds = [...prevSelectedFilters[filterType], filterId];
      }
  
      return {
        ...prevSelectedFilters,
        [filterType]: newFilterIds,
      };
    });
  };

  const getPlaces = (page, request, scrollAfter = true) => {
    let categoryIds = []
    let classificationsIds = []
    let featureIds = []
    let newSortingOrder = sortingOrder
    let newPage = page

    if (!request) {
      categoryIds = selectedFilters.categoryIds
      featureIds = selectedFilters.featureIds
      classificationsIds = selectedFilters.classificationsIds
      newSortingOrder = sortingOrder
      newPage = page
    } else {
      categoryIds = request.categoryIds
      featureIds = request.featureIds
      classificationsIds = request.classificationsIds
      newSortingOrder = request.sortingOrder
      newPage = request.page
    }

      getRequest(`places/allFiltered?categoryIds=${categoryIds}&sort=${newSortingOrder}&language=${i18n.language}&page=${newPage}&classificationIds=${classificationsIds}&featureIds=${featureIds}`)
        .then((response) => response.data)
        .then((pageablePlaces) => {
          const newData = pageablePlaces.data;
          setPlacesList(newData);
          setTotalPages(Math.ceil(pageablePlaces.total / perPage));
          setIsLoading(false);
          if (scrollAfter) {
            let scrollY = parseFiltersFromURL().scrollY
            setTimeout(() => {
              window.scrollTo({
                left: 0,
                top: scrollY ? scrollY : 0,
                behavior: 'smooth'
              });
            }, 300);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);

    let searchParams = new URLSearchParams(parseFiltersFromURL())
    searchParams.set('scrollY', 0)
    searchParams.set('page', value)
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      });
    }, 300);
    let stringParams = searchParams.toString()
    navigate(`?${stringParams}`, { replace: true });
    getPlaces(value, null, false);
  };

  const linksData = [
    { path: "/", label: "home" },
    { path: "/places/all", label: "places" }
  ];

  const onSaveFilters = () => {
    setAnchorEl(null);
    const searchParams = new URLSearchParams(selectedFilters);
    setCurrentPage(1)
    searchParams.set('sortingOrder', sortingOrder);
    searchParams.set('page', initialPage);
    const stringParams = searchParams.toString();
    navigate(`?${stringParams}`, { replace: true });
    getPlaces(1, null, false)
  }

  useEffect(() => {
    getRequest(`categories/all`)
            .then((response) => response.data)
            .then((data) => {
              setCategories(data)
            })
            .catch((error) => {
                console.log(error)
            });
  }, [sortingOrder]);

  useEffect(() => {
    if (removedSelectedFilters) {
      setRemovedSelectedFilters(false)
    }
    if (initialMount.current) {
      const newFilters = { categoryIds: [], featureIds: [], classificationsIds: [] };
      const urlFilters = parseFiltersFromURL()
      if (urlFilters.categoryIds?.length === 0 && urlFilters.classificationsIds?.length === 0 && urlFilters.featureIds?.length === 0) {
          getFiltersByCriteria(newFilters);
      } else {
        getFiltersByCriteria(urlFilters);
      }
    } else {
      getFiltersByCriteria(selectedFilters)
    }

    initialMount.current = false;
  }, [selectedFilters, i18n.language]); 

  useEffect(() => {
    const urlFilters = parseFiltersFromURL()

    const request = {
      categoryIds: urlFilters.categoryIds,
      classificationsIds: urlFilters.classificationsIds,
      featureIds: urlFilters.featureIds,
      sortingOrder: urlFilters.sortingOrder,
      page: urlFilters.page
    }

    getPlaces(urlFilters.page, request)

  }, [params.categoryIds], [params.classificationIds], [params.featureIds], sortingOrder);

  useEffect(() => {
    if (i18n.language !== prevLanguage.current) {
      setPlacesList([]);
      setCurrentPage(initialPage);
      prevLanguage.current = i18n.language;
      const searchParams = new URLSearchParams(selectedFilters);
      searchParams.set('sortingOrder', sortingOrder);
      searchParams.set('page', initialPage);
      searchParams.set("scrollY", window.scrollY)
      const parameters = searchParams.toString()
      navigate(`?${parameters}`, { replace: true });
      getPlaces(currentPage)
    }
  }, [i18n.language]); 


  useEffect(() => {
      prevSearch.current = location.search
      const urlFilters = parseFiltersFromURL()
      setSelectedCategories(urlFilters.selectedCategories)
      setSortingOrder(SortingOrder.ALPHABETICAL)
      setSelectedFilters(urlFilters);
      getPlaces(1, urlFilters)

  }, [location.search]);

  return (
    <div className={classnames(styles.mainContent)}>
        <Helmet htmlAttributes={{ lang: i18n.language }}>
            <title>{Titles.wgt + t("places")}</title>
        </Helmet>
        {isLoading &&  
            <div className={classnames(styles.loading)} > 
                <ClipLoader color="#FE754B" size={250}/> 
            </div>
        }
        <div className={classnames('row', styles.row, styles.timePeriod)}>
            <DynamicBreadcrumbs links={linksData} />
            <div className={classnames(styles.filterAndSortingRow)}>
              <div className={classnames(styles.sortingWrapper, 'col-6', 'col-xxl-4', 'col-xl-4', 'col-lg-5', 'col-md-4', 'col-sm-6')}>
                <Select
                      renderValue={() => t("sort")}
                      value={sortingOrder}
                      onChange={handleDropdownChange}
                      IconComponent={CustomArrowIcon}
                      sx={{
                        width: '100%',
                        height: '40px',
                        color: 'white',
                        fontFamily: 'Arial',
                        fontSize: '15px',
                        border: '1px solid #FE754B',
                        borderRadius: '8px',
                        '& ,MuiPaper-root .MuiPopover-paper .MuiMenu-paper': {
                          backgroundColor: 'transparent',
                        },
                        '& .MuiPaper-root': {
                          backgroundColor: '#1A2532',
                      
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          boxShadow: 'none',
                          outline: 'none',
                          border: 'none'
                        },
                        '& .MuiSelect-icon': {
                          marginRight: '5px'
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            backgroundColor: 'transparent',
                          },
                        },
                        sx: {
                          '& .MuiMenuItem-root.Mui-selected': {
                            backgroundColor: 'transparent',
                            '&:hover': {
                              backgroundColor: 'inherit',  
                            }
                          },
                          '& .MuiMenuItem-root.Mui-selected.Mui-focusVisible': {
                            backgroundColor: 'transparent'
                          },
                          marginTop: '10px',
                          '& .MuiList-root': {
                            backgroundColor: '#1A2532',
                            border: '1px solid #FE754B',
                            borderRadius: '8px',
                            '& .MuiMenuItem-root:hover': {
                              backgroundColor: '#FE754B',
                              color: 'white', 
                            } 
                          },
                        },
                      }}
                    >
                      {Object.entries(SortingOrder).map(([key, value]) => (
                        <MenuItem key={key} value={value} sx={{ 
                          color: 'white',
                          '&:hover svg': {
                            color: 'white'
                          }
                          }}>
                          {SortingOrderTranslation[key]}
                          {sortingOrder === value && <CheckIcon sx={{pl: '10px', ml: 'auto', color: '#FE754B' }} />} 
                        </MenuItem>
                      ))}
                </Select>
              </div>
              <div className={classnames(styles.filterWrapper, 'col-6', 'col-xxl-4', 'col-xl-4', 'col-lg-5', 'col-md-4', 'col-sm-6')}>
                <Button
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    aria-describedby={popoverId}
                    variant="contained"
                    onClick={handleClickPopover}
                    sx={{
                      width: '100%',
                      backgroundColor: '#FE754B',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderRadius: '8px',
                      textTransform: 'none',
                      fontFamily: 'Arial',
                      fontSize: '15px',
                      fontWeight: '400', 
                      '& .MuiButton-endIcon': {
                        position: 'absolute',
                        right: '8px',
                        marginLeft: '0',
                        marginRight: '5px'
                      },
                      ':hover': {
                        backgroundColor: '#4137AF'
                      }
                    }}
                    endIcon={openPopover ? <WhiteDropDownLess /> : <WhiteDropDownMore /> }
                >
                  {t("filters")}
                </Button>

                <Popover
                  id={popoverId}
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  marginThreshold={0}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{
                    '.MuiPopover-paper': {
                      width: anchorEl?.clientWidth,
                      maxWidth: anchorEl?.clientWidth,
                      border: "1px solid #FE754B",
                      marginTop: '10px',
                      backgroundColor: '#1A2532',
                      maxHeight: '100%!important',
                      
                      '@media (max-width:992px)': {
                        position: 'fixed',
                        top: '0!important',
                        left: '0!important',
                        right: '0!important',
                        bottom: '0!important',
                        padding: '0!important',
                        margin: '0!important',
                        width: '100%!important',
                        height: '100%!important',
                        maxWidth: '100%!important',
                        maxHeight: '100%!important',
                        borderRadius: 0,
                        border: 'none',
                      }
                    }
                  }}
                >
                  <div 
                    className={classnames(styles.mobileFiltersHeadingContainer)}
                  >
                      <div 
                          className={classnames(styles.filtersMobileHeading)}
                      >
                          {t("filters")}
                      </div>
                      <img 
                          className={classnames(styles.closeFiltersIcon)}
                          src={largeWhiteCloseIcon}
                          alt=""
                          onClick={() => setAnchorEl(null)}
                      />
                          
                  </div>

                  <div 
                    className={classnames(styles.appliedFiltersHeading)}
                  >
                      <div className={classnames(styles.appliedFiltersTitle)}>
                          {t("appliedFilters")}
                      </div>
                      <div
                        onClick={() => clearAllFilters()}
                        className={classnames(styles.clearFilters)}
                      >
                          <div className={classnames(styles.clearTitle)}>
                            {t("clearAll")}
                          </div>
                          <img 
                            src={clearImageOrange}
                            alt=""
                            />
                      </div>
                  </div>

                  <div className={classnames(styles.appliedFiltersContainer)}>
                      {
                          (selectedFilters?.categoryIds?.length === 0 && selectedFilters?.classificationsIds?.length === 0 && selectedFilters?.featureIds?.length === 0) ? (
                            <div className={classnames(styles.appliedFilterWrapperEmpty)}>
                                <img 
                                  alt=""
                                  src={clearImageWhite} 
                                  />
                            </div>
                          ) : (
                              <>
                                  {
                                      selectedFilters?.categoryIds?.map((subcategoryId) => {
                                          return (
                                              <div className={classnames(styles.appliedFilterWrapper)}>
                                                  <div className={classnames(styles.filterName)}> 
                                                      {getCategoryNameById(subcategoryId)}
                                                  </div>
                                                  <img 
                                                      onClick={() => handleFilterChange('categoryIds', subcategoryId)}
                                                      className={classnames(styles.removeFilterIcon)} 
                                                      src={clearImageWhite}
                                                      alt=""
                                                  />
                                              </div>
                                          );
                                      })
                                  }
                                  {
                                      selectedFilters?.classificationsIds?.map((classificationId) => {
                                          return (
                                              <div className={classnames(styles.appliedFilterWrapper)}>
                                                  <div className={classnames(styles.filterName)}>
                                                      {getClassificationNameById(classificationId)}
                                                  </div>
                                                  <img 
                                                      onClick={() => handleFilterChange('classificationsIds', classificationId)}
                                                      className={classnames(styles.removeFilterIcon)} 
                                                      src={clearImageWhite}
                                                      alt=""
                                                  />
                                              </div>
                                          );
                                      })
                                  }
                                  {
                                      selectedFilters?.featureIds?.map((featureId) => {
                                          return (
                                              <div className={classnames(styles.appliedFilterWrapper)}>
                                                  <div className={classnames(styles.filterName)}>
                                                      {getFeatureNameById(featureId)}
                                                  </div>
                                                  <img 
                                                      onClick={() => handleFilterChange('featureIds', featureId)}
                                                      className={classnames(styles.removeFilterIcon)} 
                                                      src={clearImageWhite}
                                                      alt=""
                                                  />
                                              </div>
                                          );
                                      })
                                  }
                              </>
                          )
                      }
                  </div>
     

                  { filters.categories?.length > 0  && 
                  <>
                  <div
                    onClick={() => {
                      setOpenCategories(!openCategories);
                      if (openFeatures) setOpenFeatures(false);
                      if (openClassifications) setOpenClassifications(false);
                    }}
                    className={classnames(styles.filterHeadingPopover)}
                    >
                    <div>{t("category")}</div>
                    {openCategories ? <WhiteDropDownLess /> : <WhiteDropDownMore />}
                  </div>
                
                  <Collapse in={openCategories} timeout="auto" unmountOnExit>
                    <div className={classnames(styles.filetItemsContainer)}>
                      {
                        filters.categories?.map((filter) => (
                          <MenuItem 
                            disableRipple
                            disableTouchRipple
                            key={filter.id} value={filter.id}
                            onClick={() => handleFilterChange('categoryIds', filter.id)}
                            >
                              <Checkbox 
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                                sx={{
                                  color: "#FE754B",
                                  marginRight: '10px',
                                  marginTop: '5px',
                                  marginBottom: '5px',
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 20,
                                    color: "#FE754B",
                                  },
                                  "&.MuiCheckbox-root": {
                                    borderRadius: 0,
                                    padding: 0,
                                  },
                                  "& svg": {
                                    scale: "1.4",
                                  },
                                  '&.Mui-checked': {
                                    color: '#FE754B',
                                    backgroundColor: "white",
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 20,
                                      color: "FE754B",
                                    },
                                    "&.MuiCheckbox-root": {
                                      borderRadius: 0,
                                      padding: 0,
                                    },
                                    "& svg": {
                                      scale: "1.4",
                                    },
                                  }
                                }}
                                checked={selectedFilters["categoryIds"].includes(filter.id)} 
                              />
                              <div className={classnames(styles.filterItemText)}>
                                {filter.name}
                              </div>
                               
                          </MenuItem>          
                        ))
                      }
                    </div>
                  </Collapse>
                  </>
                  }

                  { filters.classifications?.length > 0  && 
                  <>
                  <div
                    onClick={() => {
                      setOpenClassifications(!openClassifications);
                      if (openFeatures) setOpenFeatures(false);
                      if (openCategories) setOpenCategories(false);
                    }}
                    className={classnames(styles.filterHeadingPopover)}
                    >
                    <div>{t("specificity")}</div>
                    {openClassifications ? <WhiteDropDownLess /> : <WhiteDropDownMore />}
                  </div>
                
                  <Collapse in={openClassifications} timeout="auto" unmountOnExit>
                    <div className={classnames(styles.filetItemsContainer)}>
                      {
                        filters.classifications?.map((filter) => (
                          <MenuItem 
                            disableRipple
                            disableTouchRipple
                            key={filter.id} value={filter.id}
                            onClick={() => handleFilterChange('classificationsIds', filter.id)}
                            >
                              <Checkbox 
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                                sx={{
                                  color: "#FE754B",
                                  marginRight: '10px',
                                  marginTop: '5px',
                                  marginBottom: '5px',
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 20,
                                    color: "#FE754B",
                                  },
                                  "&.MuiCheckbox-root": {
                                    borderRadius: 0,
                                    padding: 0,
                                  },
                                  "& svg": {
                                    scale: "1.4",
                                  },
                                  '&.Mui-checked': {
                                    color: '#FE754B',
                                    backgroundColor: "white",
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 20,
                                      color: "FE754B",
                                    },
                                    "&.MuiCheckbox-root": {
                                      borderRadius: 0,
                                      padding: 0,
                                    },
                                    "& svg": {
                                      scale: "1.4",
                                    },
                                  }
                                }}
                                checked={selectedFilters["classificationsIds"].includes(filter.id)} 
                              />
                              <div className={classnames(styles.filterItemText)}>
                                {filter.name}
                              </div>
                               
                          </MenuItem>          
                        ))
                      }
                    </div>
                  </Collapse>
                  </>
                  }
                  { filters.features?.length > 0  && 
                  <>
                  <div 
                    onClick={() => {
                      setOpenFeatures(!openFeatures);
                      if (openClassifications) setOpenClassifications(false);
                      if (openCategories) setOpenCategories(false);
                    }}
                    className={classnames(styles.filterHeadingPopover)}
                    >
                    <div>{t("features")}</div>
                    {openFeatures ? <WhiteDropDownLess /> : <WhiteDropDownMore />}
                  </div>
                
                  <Collapse in={openFeatures} timeout="auto" unmountOnExit>
                    <div className={classnames(styles.filetItemsContainer)}>
                      {
                        filters.features?.map((filter) => (
                          <MenuItem 
                            disableRipple
                            disableTouchRipple
                            onClick={() => handleFilterChange('featureIds', filter.id)}
                            key={filter.id} value={filter.id}>
                            <Checkbox
                              disableRipple
                              disableFocusRipple
                              disableTouchRipple
                              checked={selectedFilters["featureIds"].includes(filter.id)}
                              sx={{
                                color: "#FE754B",
                                marginRight: '10px',
                                marginTop: '5px',
                                marginBottom: '5px',
                                "& .MuiSvgIcon-root": {
                                  fontSize: 20,
                                  color: "#FE754B",
                                },
                                "&.MuiCheckbox-root": {
                                  borderRadius: 0,
                                  padding: 0,
                                },
                                "& svg": {
                                  scale: "1.4",
                                },
                                '&.Mui-checked': {
                                  color: '#FE754B',
                                  backgroundColor: "white",
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 20,
                                    color: "FE754B",
                                  },
                                  "&.MuiCheckbox-root": {
                                    borderRadius: 0,
                                    padding: 0,
                                  },
                                  "& svg": {
                                    scale: "1.4",
                                  },
                                }
                              }}
                              />
                              <div className={classnames(styles.filterItemText)}>
                                  {filter.name}
                              </div>
                          </MenuItem>
                                      
                        ))
                      }
                    </div>
                  </Collapse>
                  </>
                  }
                  <div className={classnames(styles.buttonWrappenSave)}>
                    <OrangeButtonVioletHover
                      title={t("save")}
                      onClick={() => onSaveFilters()}
                    >

                    </OrangeButtonVioletHover>
                  </div>

                </Popover>
              </div>
            </div>
        </div>
        <div className="row">
          {placesList && placesList.length > 0 ? (
            placesList.map((place, index) => (
              <div key={place.id + "-" + index} className={classnames('col-xs-12', 'col-sm-12', 'col-md-6', 'col-lg-4', 'col-xl-4', 'col-xxl-4', 'col-12', styles.card)}>
                <PlaceCard
                        title={place.placeName}
                        description={place.placeDescription}
                        logo={place.mainImage}
                        date={getFormmatedWorkingHours(place.workingHours)}
                        uri={`/places/${place.uri}`}
                        isFavourite={place.isFavourite}
                        address={place.placeAddress}
                        rating={place.rating}
                        reviewCount={place.reviewCount}
                        placeSpecifications={place.placeSpecifications}
                        breadcrumbs={linksData}
                        isWgtChoice={place.isWgtChoice}
                        onNavigate={() => {
                          const searchParams = new URLSearchParams();
                          prevSearch.current = location.search
                          searchParams.set('selectedCategories', selectedCategories);
                          searchParams.set('sortingOrder', sortingOrder);
                          searchParams.set("scrollY", window.scrollY);
                          searchParams.set("page", currentPage);
                          const parameters = searchParams.toString()
                          navigate(`?${parameters}`, { replace: true });
                        }}
                        />
              </div>
            ))
          ) : (
            <div className={classnames('col-12', styles.noPlacesContainer)}>
              <img src={noPlaces} alt =""/>
              <p className={classnames(styles.noPlacesText)}>{t("nothingExists")}</p>
            </div>
          )}
        </div>
        { placesList && placesList.length > 0 &&
          <div className={classnames(styles.paginationContainer)}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                '& .MuiPaginationItem-root': {
                  color: 'white',
                },
                '& .Mui-selected': {
                  backgroundColor: '#4137AF!important', 
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(65, 55, 175, 0.8)',
                  },
                },
                '& .MuiPaginationItem-ellipsis': {
                  color: 'white', 
                },
                '& .MuiPaginationItem-icon': {
                  color: 'white',
                },
              }}
            />
        </div>
      }
    </div>
  );
};

export default AllPlacesPage;